import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { JwtGuard, MobileInaccessibleGuard } from '@et/guards';
import { ProfileResolver } from '@et/resolvers';
import { ETPermissions } from '@et/typings';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'app',
        component: HomeComponent,
        canActivate: [JwtGuard, MsalGuard],
        resolve: { profile: ProfileResolver },
        children: [
          {
            path: 'doc-list',
            canActivate: [MobileInaccessibleGuard, NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  ETPermissions.NotarySigningAgent,
                  ETPermissions.NotaryNotary,
                  ETPermissions.TitleSigningAgent,
                  ETPermissions.Title,
                  ETPermissions.ClosingCompany,
                  ETPermissions.Signor,
                ],
                redirectTo: '/app/profile',
              },
            },
            loadChildren: () =>
              import('@et/doc-list').then((mod) => mod.FeatureDocListModule),
          },
          {
            path: 'doc-upload',
            canActivate: [MobileInaccessibleGuard, NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  ETPermissions.NotarySigningAgent,
                  ETPermissions.NotaryNotary,
                  ETPermissions.TitleSigningAgent,
                  ETPermissions.Title,
                  ETPermissions.ClosingCompany,
                  ETPermissions.Signor,
                ],
                redirectTo: '/app/profile',
              },
            },
            loadChildren: () =>
              import('@et/doc-upload').then(
                (mod) => mod.FeatureDocUploadModule,
              ),
          },
          {
            path: 'orders',
            canActivate: [MobileInaccessibleGuard, NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  ETPermissions.LenderClosingDepartment,
                  ETPermissions.LenderLoanOfficer,
                ],
                redirectTo: '/app/doc-list',
              },
            },
            loadChildren: () =>
              import('@et/orders').then((mod) => mod.FeatureOrdersModule),
          },
          {
            path: 'profile',
            canActivate: [MobileInaccessibleGuard],
            loadChildren: () =>
              import('@et/profile').then((mod) => mod.FeatureProfileModule),
          },
          {
            path: 'signing',
            loadChildren: () =>
              import('@et/signing').then((mod) => mod.FeatureSigningModule),
          },
        ],
      },
      {
        path: 'link',
        loadChildren: () =>
          import('@et/secure-links').then(
            (mod) => mod.FeatureSecureLinksModule,
          ),
      },
      {
        path: 'auth',
        loadChildren: () => import('@et/auth').then((mod) => mod.AuthModule),
      },
      {
        path: 'error',
        loadChildren: () =>
          import('@et/error-pages').then((mod) => mod.ErrorPagesModule),
      },
      { path: '', redirectTo: 'app/orders', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
