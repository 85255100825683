import { environment } from '@et/environment';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '@et/auth';
import { ETPermissions } from '@et/typings';

@Component({
  selector: 'et-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  showProfileMenu = false;
  isProd = environment.production;
  permissions = ETPermissions;

  constructor(private authService: AuthService) {}
  /**
   * Log out the user.
   * @returns None
   * @memberof NavbarComponent
   */
  logout(): void {
    this.authService.logout();
  }
  /**
   * close profile menu when clicking out of element
   *
   * @param {MouseEvent} ev
   * @memberof NavbarComponent
   */
  closeProfileMenu(ev: MouseEvent) {
    ev.stopPropagation();
    if (this.showProfileMenu) {
      this.showProfileMenu = false;
    }
  }
}
