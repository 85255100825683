import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'et-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, OnDestroy {
  showNavbar = true;
  eventsSub!: Subscription;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.eventsSub = this.router.events.subscribe(() => {
      this.showNavbar = this.router.url !== '/app/doc-upload/upload';
    });
  }

  ngOnDestroy(): void {
    this.eventsSub.unsubscribe();
  }
}
