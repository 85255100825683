<div
  class="px-4 py-2 h-fit sm:h-[96px] mb-2 sm:shadow-header sm:bg-primary-content"
>
  <!-- Wrapper for buttons -->
  <div class="container h-full max-w-screen-xl p-0 mx-auto navbar">
    <!-- Left buttons wrapper -->
    <div class="self-end mb-2 navbar-start">
      <div class="px-2 mx-2 navbar-center lg:hidden">
        <div class="flex items-stretch">
          <img class="h-10" src="assets/images/EscrowTabLogo.svg" />
        </div>
      </div>

      <!-- Documents button -->
      <a
        *ngxPermissionsOnly="[
          permissions.NotarySigningAgent,
          permissions.NotaryNotary,
          permissions.TitleSigningAgent,
          permissions.Title,
          permissions.ClosingCompany,
          permissions.Signor
        ]"
        class="items-center hidden mr-6 cursor-pointer text-midnight md:flex h-[33px] group"
        routerLink="doc-list"
        routerLinkActive="text-brand-green"
      >
        <i
          class="text-2xl icon-Document group-hover:decoration-2 group-hover:underline-offset-8 group-hover:underline"
          routerLinkActive="text-brand-green decoration-2 underline-offset-8 underline"
        ></i>
        <p class="mt-1 ml-4 text-base font-bold">
          <ng-container *transloco="let t; read: 'navbar'">
            <span>{{ t('documents') }}</span>
          </ng-container>
        </p>
      </a>

      <!-- Orders button -->
      <a
        *ngxPermissionsOnly="[
          permissions.LenderClosingDepartment,
          permissions.LenderLoanOfficer
        ]"
        class="items-center hidden mr-6 cursor-pointer text-midnight md:flex h-[33px] group"
        routerLink="orders"
        routerLinkActive="text-brand-green"
      >
        <i
          class="text-2xl icon-Document group-hover:decoration-2 group-hover:underline-offset-8 group-hover:underline"
          routerLinkActive="text-brand-green decoration-2 underline-offset-8 underline"
        ></i>
        <p class="mt-1 ml-4 text-base font-bold">
          <ng-container *transloco="let t; read: 'navbar'">
            <span>{{ t('orders') }}</span>
          </ng-container>
        </p>
      </a>

      <!-- My Accounts button -->
      <a
        class="items-center hidden cursor-pointer text-midnight md:flex h-[33px] group"
        [routerLink]="'profile'"
        [routerLinkActive]="['!text-accent-focus']"
      >
        <i
          class="text-2xl icon-User group-hover:decoration-2 group-hover:underline-offset-8 group-hover:underline"
          routerLinkActive="text-brand-green decoration-2 underline-offset-8 underline"
        ></i>
        <p class="mt-1 ml-4 text-base font-bold">
          <ng-container *transloco="let t; read: 'navbar'">
            <span>{{ t('myAccount') }}</span>
          </ng-container>
        </p>
      </a>
    </div>

    <!-- Logo -->
    <div class="self-end hidden px-2 mb-2 navbar-center lg:flex">
      <div class="flex items-stretch">
        <img class="h-[52px] w-auto" src="assets/images/EscrowTabLogo.svg" />
      </div>
    </div>

    <!-- Right buttons wrapper -->
    <div class="self-end pr-2 mb-2 navbar-end">
      <a
        href="https://help.escrowtab.com/hc/en-us"
        target="_blank"
        class="hidden pr-4 text-base font-bold text-midnight lg:flex md:flex link link-hover"
      >
        <p>
          <ng-container *transloco="let t; read: 'navbar'">
            <span>{{ t('support') }}</span>
          </ng-container>
        </p>
      </a>
      <button
        class="hidden capitalize rounded-full text-midnight lg:flex md:flex btn btn-sm btn-outline"
        (click)="logout()"
      >
        <p>
          <ng-container *transloco="let t; read: 'navbar'">
            <span>{{ t('logout') }}</span>
          </ng-container>
        </p>
      </button>

      <!-- User amd menu for small screens -->
      <!-- Menu is hidden due to mobile guard -->
      <div class="relative ml-3 sm:hidden" *ngIf="false">
        <div>
          <button
            type="button"
            class="flex text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            id="user-menu-button"
            aria-expanded="false"
            aria-haspopup="true"
            (click)="showProfileMenu = true"
          >
            <span class="sr-only">Open user menu</span>
            <img
              class="w-8 h-8 rounded-full"
              [src]="'assets/images/user-icon.svg'"
              alt=""
            />
          </button>
        </div>
        <div
          *ngIf="showProfileMenu"
          interfaceClickOnOutside
          (clickOutside)="closeProfileMenu($event)"
          class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu-button"
          tabindex="-1"
        >
          <a
            class="block px-4 py-2 text-sm text-gray-700 link link-hover"
            role="menuitem"
            tabindex="-1"
            [routerLink]="'profile'"
            [routerLinkActive]="['!text-accent-focus']"
            >My Account</a
          >
          <a
            class="block px-4 py-2 text-sm text-gray-700 link link-hover"
            role="menuitem"
            tabindex="-1"
            >Settings</a
          >
          <a
            href="https://help.escrowtab.com/hc/en-us"
            class="block px-4 py-2 text-sm text-gray-700 link link-hover"
            role="menuitem"
            tabindex="-1"
            >Support</a
          >
          <a
            class="block px-4 py-2 text-sm text-gray-700 link link-hover"
            role="menuitem"
            tabindex="-1"
            (click)="logout()"
            >Logout</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
