import { ApiModule } from '@et/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';

import { BrowserModule } from '@angular/platform-browser';
import { AuthModule } from '@et/auth';

import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { DirectivesModule } from '@et/directives';
import { I18nModule } from '@et/i18n';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { environment } from '@et/environment';
import { NotificationsModule } from '@et/notifications';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { MSALProviders } from './add-config';

import { AppRoutingModule } from './app-routing.module';
import { ProfileState } from '@et/guards';
import { Router } from '@angular/router';
import { ErrorLoggerModule, GlobalErrorHandler } from '@et/error-logger';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppApiModule } from './app-api.module';

@NgModule({
  declarations: [AppComponent, NavbarComponent, HomeComponent, FooterComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    DirectivesModule,
    HttpClientModule,
    I18nModule,
    NgxsModule.forRoot([ProfileState]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    AppRoutingModule,
    ApiModule.forRoot({
      rootUrl: `${environment.apiServer.domain}`,
    }),
    NotificationsModule,
    ErrorLoggerModule,
    NgxPermissionsModule.forRoot(),
    AppApiModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ...MSALProviders,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
