import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from '@et/environment';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';

Sentry.init({
  dsn: environment.sentryDns,
  environment: environment.environment,
  enabled: environment.production,
  autoSessionTracking: true,
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        'localhost',
        environment.docPrepService.domain,
        environment.apiServer.domain,
        environment.docio.domain,
      ],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.environment === 'prod' ? 1.0 : 0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
