import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@et/environment';
import {
  Observable,
  filter,
  interval,
  map,
  retry,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';
import {
  DocumentProcessData,
  DocumentProcessDataResponse,
} from './../models/document-response-api-model';
import {
  DocPrepDocumentsList,
  DocPrepDocumentStatusResponse,
  DocprepMarkup,
  DocPrepResponse,
  DocPrepTemplatesListRequest,
  DocumentMarkupStatus,
  PacketListItem,
  PacketsClosingType,
} from '@et/typings';
import { isJSON } from '@et/utils';

/**
 * Docs processing
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentProcessApiService {
  constructor(private http: HttpClient) {}

  /**
   * Path part for document preparation
   */
  static readonly DocPath = '/api/document/add';

  /**
   * Process document
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  processDocuments(
    body: DocumentProcessData,
  ): Observable<DocumentProcessDataResponse> {
    const formData = this.createFormData(body);

    return this.http.post<DocumentProcessDataResponse>(
      environment.docPrepService.domain + DocumentProcessApiService.DocPath,
      formData,
    );
  }

  /**
   * A helper method to create formData from DocumentProcessData
   *
   * @param {DocumentProcessData} body - DocumentProcessData to format
   * @returns FormData
   */
  createFormData(body: DocumentProcessData): FormData {
    const closingDateTime = new Date(
      body?.meta?.closingDate + ', ' + body?.meta?.closingTime,
    );

    const { buyers, sellers, signorCount } = this.getBuyersAndSellers(body);

    const data: any = {
      externalId: body?.meta?.documentId,
      type: 'LoanDocument',
      name: body?.meta?.docTitleName,
      isTestDocument: body?.meta?.isTestDocument,
      markupRequired: body?.meta?.sendToAutoDocumentPreparation === true,
      metadata: {
        state: body?.meta?.state,
        titleCompany: body?.meta?.titleAgentCompany,
        signors: signorCount,
        hasEnote: body?.meta?.hasENote === 'eNote - Y',
        flaggedPages: body?.meta?.flaggedPages,
        titleAgentId: body?.meta?.titleAgentId,
        titleAgent: body?.meta?.titleAgentDisplayName,
        titleAgentEmail: body?.meta?.titleAgentEmail,
        titleCompanyName: body?.meta?.companyName,
        signingAgent: body.meta?.signingAgent,
        signingAgentEmail: body.meta?.signingAgentEmail,
        closingTime: closingDateTime,
        closingType: body?.meta?.closingType,
        buyers: buyers,
        sellers: sellers,
        signorInfo: body?.meta?.signorInfo,
      },
      notifications: this.makeNotifications(body),
    };

    // Add processing options if they exist
    if (body?.meta?.processingOptions) {
      data.processingOptions = body.meta.processingOptions;
    }

    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    if (body.file) {
      formData.append('attachedFile', body.file);
    }
    return formData;
  }

  /**
   * This function updates PDF file in autotag
   * @param packet - PacketListItem
   * @param newPageOrder - updated page order
   * @param file - new PDF file
   * @param markupRequired - markup required
   */
  updateDocument(
    packet: PacketListItem,
    newPageOrder: Array<number | null>,
    file: File,
    markupRequired: boolean,
  ) {
    const formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        externalId: packet.packetId,
        type: 'LoanDocument',
        name: packet.packetName,
        isTestDocument: packet.isTestPacket,
        markupRequired: markupRequired,
        metadata: {
          state: packet.packetState,
          signors: packet.signers?.length,
          hasEnote: packet.eNote,
          titleAgentId: packet.titleAgentId,
          signingAgent: packet.signingAgentId,
          closingTime: packet.closingDate,
        },
        notifications: [
          {
            event: 'MarkupComplete',
            action: 'InternalCallback',
            data: {
              service: 'Packets',
            },
          },
        ],
        modificationInfo: {
          newPageOrder,
        },
      }),
    );
    formData.append('attachedFile', file);

    return this.http.post<DocumentProcessDataResponse>(
      environment.docPrepService.domain + DocumentProcessApiService.DocPath,
      formData,
    );
  }

  /**
   * Updates the PDF and its associated markup data.
   *
   * This function creates a `FormData` object containing the provided file and markup data.
   * It appends the file under the key 'attachedFile' and the serialized markup data under the key 'data'.
   * The function then sends an HTTP POST request to processing API endpoint with the `FormData`.
   *
   * @param {File} file - The PDF file to be updated.
   * @param {DocprepMarkup} data - The markup data associated with the PDF file.
   * @returns {Observable<DocumentProcessDataResponse>} - An observable that emits the response from the document processing API.
   */
  updatePDFAndMarkup(
    file: File,
    data: DocprepMarkup,
  ): Observable<DocumentProcessDataResponse> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(data));
    formData.append('attachedFile', file);

    return this.http.post<DocumentProcessDataResponse>(
      environment.docPrepService.domain + DocumentProcessApiService.DocPath,
      formData,
    );
  }

  /**
   * Fetches the status of a document with the provided external Id (Packet Id).
   * @param {DocumentMarkupStatus} externalId - Packet ID of the document.
   */
  getDocumentStatus(externalId: string) {
    return this.http
      .get<DocPrepDocumentStatusResponse>(
        environment.docPrepService.domain +
          '/api/document/' +
          externalId +
          '/status-external',
      )
      .pipe(
        retry({ count: 1, delay: 200 }),
        map((res) => {
          const status = res.data;
          const pagesProgressStatus: string[] = isJSON(res.message)
            ? JSON.parse(res.message)
            : [];
          return { status, pagesProgressStatus };
        }),
      );
  }

  /**
   * Fetches a list of document preparation templates.
   *
   * @param {DocPrepTemplatesListRequest} body - The request payload.
   * @returns {Observable<DocPrepResponse>} An observable that emits the list of templates.
   */
  getTemplates(
    body: DocPrepTemplatesListRequest,
  ): Observable<DocPrepDocumentsList> {
    return this.http
      .post<DocPrepResponse>(
        environment.docPrepService.domain + '/api/document/list-templates',
        body,
      )
      .pipe(
        retry({ count: 1, delay: 200 }),
        map((res) => res.data),
      );
  }

  /**
   * Deletes a document template by its ID.
   *
   * @param {string} documentId - The ID of the document whose template is to be deleted.
   * @returns {Observable<any>} An observable that emits the response from the delete operation.
   */
  deleteTemplate(documentId: string): Observable<any> {
    return this.http
      .delete(
        environment.docPrepService.domain +
          `/api/document/${documentId}/template`,
      )
      .pipe(retry({ count: 1, delay: 200 }));
  }

  /**
   * Generates an array of notifications based on the provided document process data.
   * @param {DocumentProcessData} body - The document process data.
   * @returns {Array<{event: string, action: string, data?: object}>} The generated notifications.
   */
  private makeNotifications(
    body: DocumentProcessData,
  ): Array<{ event: string; action: string; data?: object }> {
    const notifications: Array<{
      event: string;
      action: string;
      data: object;
    }> = [];

    if (
      body?.meta?.sendToAutoDocumentPreparation ||
      body?.meta?.closingType === PacketsClosingType.ELSI
    ) {
      notifications.push({
        event: 'MarkupComplete',
        action: 'InternalCallback',
        data: {
          service: 'Packets',
        },
      });
    }

    if (
      body?.meta?.sendToAutoDocumentPreparation === true &&
      body?.meta?.autoTagOnboarding === false
    ) {
      notifications.push({
        event: 'ProcessingComplete',
        action: 'InternalCallback',
        data: {
          service: 'Packets',
        },
      });
    }

    return notifications;
  }

  /**
   * Monitors the processing status of a document until it is fully processed or marked up.
   *
   * This method creates an Observable that emits the document's processing status at intervals specified by `opt.reqDelay`.
   * It continuously polls the document's status using until the document's status is either PROCESSED or MARKEDUP.
   * If `opt.ignoreFirstProcessed` is true, the method ignores the first occurrence of the document being in the 'Processing'
   * state before starting to emit status updates. This is useful for scenarios where an immediate 'Processing'
   * status is expected and should not trigger any action.
   *
   * The Observable completes once the document reaches a final state.
   *
   * @param {string} packetId - Packet Id
   * @param {Object} opt - Optional parameters including `reqDelay`, the polling interval in milliseconds, and `ignoreFirstProcessed`,
   *                       a flag to ignore the first 'Processing' status.
   * @param {number} [opt.reqDelay=1000] - The delay between status requests in milliseconds. Defaults to 1000ms.
   * @param {boolean} opt.ignoreFirstProcessed - Whether to ignore the first 'Processing' status. Defaults to false.
   * @returns {Observable<{status: DocumentMarkupStatus, pagesProgressStatus: string[]}>} An Observable that emits the document's
   *          processing status and pages progress status. Completes when the document is either PROCESSED or MARKEDUP.
   */
  awaitDocumentProcessed(
    packetId: string,
    opt: { reqDelay?: number; ignoreFirstProcessed: boolean } = {
      reqDelay: 1000,
      ignoreFirstProcessed: false,
    },
  ): Observable<{
    status: DocumentMarkupStatus;
    pagesProgressStatus: string[];
  }> {
    opt.reqDelay = opt.reqDelay ?? 1000;
    return new Observable<{
      status: DocumentMarkupStatus;
      pagesProgressStatus: string[];
    }>((subscriber) => {
      let response: {
        status: DocumentMarkupStatus;
        pagesProgressStatus: string[];
      };
      let processingStarted = false;
      const status$ = interval(opt.reqDelay).pipe(
        switchMap(() => this.getDocumentStatus(packetId)),
        tap((res) => (response = res)),
        filter(({ status }) => {
          if (!opt.ignoreFirstProcessed) return true;
          if (!processingStarted && status == 'Processing') {
            processingStarted = true;
          }
          return processingStarted;
        }),
        takeWhile(
          ({ status }) =>
            status !== DocumentMarkupStatus.PROCESSED &&
            status !== DocumentMarkupStatus.MARKEDUP,
        ),
      );

      const subscription = status$.subscribe({
        next: ({ status, pagesProgressStatus }) =>
          subscriber.next({ status, pagesProgressStatus }),
        complete: () => {
          subscriber.next(response);
          subscriber.complete();
        },
      });

      return () => subscription.unsubscribe();
    });
  }

  /**
   * This function extracts the number of buyers and sellers from the provided body.
   *
   * @param {Object} body - The body from which to extract the buyers and sellers.
   * @param {Object} body.meta - The meta object containing the buyers and sellers information.
   * @param {number} body.meta.buyers - The number of buyers.
   * @param {number} body.meta.sellers - The number of sellers.
   * @param {string} body.meta.isBuyer - A string indicating whether the signor is a buyer or a seller.
   * @param {number} body.meta.signorCount - The number of signors.
   */
  private getBuyersAndSellers(body: DocumentProcessData) {
    let buyers = body.meta.buyers;
    let sellers = body.meta.sellers;
    const signorCount = body.meta.signorCount;

    if (!sellers && !buyers) {
      if (body?.meta?.isBuyer?.toLowerCase().includes('buyer')) {
        buyers = body?.meta?.signorCount ?? 0;
      }
      if (body?.meta?.isBuyer?.toLowerCase().includes('seller')) {
        sellers = body?.meta?.signorCount ?? 0;
      }
    }

    // Remove CC signers from buyers and sellers
    // SignerInfo has already signers without CC
    // Reduce buyers or sellers and signorCount to be equal to signerInfo length
    const signerInfoCount = body?.meta?.signorInfo?.length ?? 0;
    const {
      buyers: rBuyers,
      sellers: rSellers,
      signorCount: rSignorCount,
    } = this.reduceSignersByMaxSigners(
      buyers,
      sellers,
      signorCount,
      signerInfoCount,
    );

    return { buyers: rBuyers, sellers: rSellers, signorCount: rSignorCount };
  }

  /**
   * Reduces the number of buyers, sellers, and signors to a maximum allowed value.
   * Remove CC signers from buyers and sellers to make it equal to signerInfo length
   *
   * @param {number} buyers - The initial number of buyers.
   * @param {number} sellers - The initial number of sellers.
   * @param {number} signorCount - The initial number of signors.
   * @param {number} maxSigners - The maximum allowed number of signers.
   * @returns {{ buyers: number, sellers: number, signorCount: number }} Reduced number of buyers, sellers, and signors.
   * @private
   */
  private reduceSignersByMaxSigners(
    buyers: number,
    sellers: number,
    signorCount: number,
    maxSigners: number,
  ): { buyers: number; sellers: number; signorCount: number } {
    buyers = buyers > maxSigners ? maxSigners : buyers;
    sellers = sellers > maxSigners ? maxSigners : sellers;
    signorCount = signorCount > maxSigners ? maxSigners : signorCount;
    return { buyers, sellers, signorCount };
  }
}
