import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

@Injectable()
export class SentryService {
  log(error: string | Error | unknown) {
    Sentry.captureException(error);
    console.error(error);
  }
}
