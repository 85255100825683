import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  AccountInfo,
  EventCallbackFunction,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import * as Sentry from '@sentry/angular-ivy';

import { Store } from '@ngxs/store';
import { GetCompany, GetFullProfile, SetAADUserWithClaims } from '@et/guards';
import { AADUserWithClaims } from '@et/typings';
import { initTracking, setUser } from '@et/utils';

@Component({
  selector: 'et-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'escrowtab';
  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msal: MsalService,
    private permissionsService: NgxPermissionsService,
    private store: Store,
  ) {}

  ngOnInit() {
    initTracking();
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        const account =
          this.msal.instance.getActiveAccount() as AADUserWithClaims;
        const { idTokenClaims } = account;
        const companyId = idTokenClaims.extension_et_company_id as string;
        const permission = account?.idTokenClaims?.[
          'extension_et_user_type'
        ] as string;
        this.permissionsService.addPermission(permission);
        this.store.dispatch([
          new SetAADUserWithClaims(account),
          new GetCompany(companyId),
          new GetFullProfile(account),
        ]);
        this.setSentryContext(account);
        setUser(account as AccountInfo);
      });
    this.setMsalInstaceCallback();
  }

  /**
   * Sets the active account after a successful login redirect
   */
  private setMsalInstaceCallback() {
    const callback = (event: {
      eventType: EventType;
      payload: { account: AccountInfo };
    }) => {
      // set active account after redirect
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        this.msal.instance.setActiveAccount(account);

        this.msal.loginRedirect();
      }
    };
    this.msal.instance.addEventCallback(callback as EventCallbackFunction);
  }

  /**
   * Sets the Sentry context to the user's name, company ID, whether they're a company admin, their
   * profile ID, and their user type
   * @param {AccountInfo | null} account - AccountInfo | null
   */
  private setSentryContext(account: AccountInfo | null) {
    Sentry.setUser({ email: account?.username });
    Sentry.setContext('user_scope', {
      email: account?.username,
      company_id: account?.idTokenClaims?.['extension_et_company_id'],
      is_company_admin:
        account?.idTokenClaims?.['extension_et_is_company_admin'],
      profile_id: account?.idTokenClaims?.['extension_et_profile_id'],
      user_type: account?.idTokenClaims?.['extension_et_user_type'],
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
