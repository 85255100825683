import { ErrorHandler, Injectable } from '@angular/core';

import { SentryService } from './sentry.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private sentryService: SentryService) {}

  handleError(error: Error) {
    this.sentryService.log(error);
  }
}
